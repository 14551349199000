import React, { useState, useEffect } from 'react';
import { urlFor } from '../sanity/sanityClient';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import { client } from '../sanity/sanityClient';
import { GoHome } from 'react-icons/go';
import { useStateContext } from '../context/StateContext';
import { AiOutlineShopping } from 'react-icons/ai';
import Cart from './Cart';
import NavBar from './NavBar';
import Loader from './Loader';
import FetchCategory from './FetchCategory';
import ProductDetail from './ProductDetail';
import './Products.css'; // Importing the CSS file
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Products = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [slugCategory, setSlugCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showCategory, setShowCategory] = useState(true);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false); 
  const { totalQuantities, showCart, setShowCart } = useStateContext();
  const { decQty, incQty, qty, onAdd } = useStateContext();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await client.fetch(
          '*[_type in [ "featured", "beauty","grocery", "clothingandfashion", "electronics", "homeandfurnitures", "fashion", "jewelryandaccessories", "medical"] && slug.current == $slug]',
          {
            slug,
          }
        );
        setProducts(data);
        if (data.length > 0) {
          const category = data[0]._type;
          const categoryProducts = await client.fetch(
            `*[_type == $category]`,
            { category }
          );
          setSlugCategory(categoryProducts);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProducts();
  }, [slug]);

  const handleFetchCategory = async (category) => {
    setShowCategory(false);
    try {
      const data = await client.fetch(`*[_type == '${category}']`);
      setSubCategories(data);
    } catch (error) {
      console.error(error);
    }
    navigate(`/category/${category}`);
  };

  const toggleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };

  const handleAddToCart = (product) => {
    onAdd(product, qty);
    toast.success(`${qty} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };


  return (
      <div className="productsPage" style={{ padding: '20px', textAlign: 'center' }}>
      <div className="NavPan"
        style={{
          display: 'flex',
          flexDirection:'row',
          justifyContent: 'space-between',
          position: 'relative',
          zIndex: '1000',
          top: '10px',
          paddingRight: '20px',
          backgroundColor: '#00000076',
          borderRadius: '10px',
        }}
      >
        <h3 style={{ color: 'white', left: '0' }}>
          <Link to={'/'} style={{color:'white',textDecoration: 'none' }}><GoHome/>Home</Link>
        </h3>
        
     <div style={{display:'flex'}}><h3><Link to="/social" style={{color:'white', textDecoration: 'none'}}>Social</Link></h3></div>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
          style={{ right: '0' }}
        >
          <AiOutlineShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        <div className={`modal ${showCart ? 'active' : ''}`}>
          <Cart />
        </div>
        <div className={`modalTwo ${ showMobileDropdown? 'active' : ''}`}>
          <NavBar handleFetchCategory={handleFetchCategory} toggleMobileDropdown={toggleMobileDropdown}/>
        </div>
      </div>

      <div className="productsDisplay">
        {showCategory ? (
          <ProductDetail products={products} />
        ) : (
          <FetchCategory subCategories={subCategories} />
        )}
      </div>

      <div className="category-products">
        {slugCategory.length > 0 ? (
          slugCategory.map((product) => (
            <div className="product-card" key={product._id}>
              <h3>{product.name}</h3>
              <div className="image-container">
                <img
                  src={urlFor(product.image[0])}
                  alt={product.name}
                  className="product-detail-image"
                />
              </div>
              
              <p>{product.details}</p>
              <p className="price">Price: {product.price.toLocaleString()}/=</p>
              <div className="buttons">
              <span className="quantity" style={{ display: 'flex', alignItems: 'center', backgroundColor:'#00000076', borderRadius:'10px' }} >
                  <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ cursor: 'pointer' }} onClick={decQty}><AiOutlineMinus /></span>
                    <span style={{ margin: '0 10px' }}>{qty}</span>
                    <span style={{ cursor: 'pointer' }} onClick={incQty}><AiOutlinePlus /></span>
                  </span>
                  <button type="button" className="add-to-cart" onClick={() => handleAddToCart(product)}>
                Add to Cart
              </button>
                </span>
              
            </div>
            </div>
          ))
        ) : (
          <div className="productsDisplay"> <Loader /></div>
        )}
      </div>
    </div>
  );
};

export default Products;
